import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import PageContainer from "../components/page-container"
import SEO from "../components/seo"
import { Title } from "../components/content/title"
import { SimpleContentLayer } from "../components/contentLayer/contentLayer"
import { SectionParagraph } from "../components/content/paragraph"
import { Container } from "../components/container/container"
import { MenuWrapper } from "../components/container/menuWrapper"

import { MemberItem } from "../content/team/memberItem"

import { useDimensions } from "../hooks/useDimensions"
import useWindowSize, { getSize } from "../hooks/useWindowSize"
import { Text } from "../components/base/typo"
import {
  formatImageData,
  formatMarkdownData,
} from "../helpers/formatGraphqlData"

export const query = graphql`
  query MuseQuery($locale: String) {
    strapiMuse(locale: { eq: $locale }) {
      paragraph
      title
    }
    allStrapiTeam(filter: { locale: { eq: $locale } }) {
      nodes {
        name
        position
        role
        degree
        linkedinLink
        facebookLink
        instagramLink
        webLink
        twitterLink
        description {
          data {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1048) {
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`

const TeamPage = ({ data, intl }) => {
  const [ref, { width }] = useDimensions()
  const { type } = useWindowSize()
  const { title, paragraph } = data.strapiMuse ?? {}
  const members =
    data.allStrapiTeam.nodes?.sort((a, b) => a.position - b.position) ?? {}

  return (
    <PageContainer>
      <SEO title="Muses" />
      <MenuWrapper pageName="muses">
        <SimpleContentLayer>
          <Container>
            <Title bold>{title}</Title>
            <SectionParagraph>
              <Text size="lg" weight="light">
                {paragraph}
              </Text>
            </SectionParagraph>
            <div ref={ref}>
              {members.map(
                (
                  {
                    role,
                    name,
                    degree,
                    image,
                    description,
                    webLink,
                    twitterLink,
                    linkedinLink,
                    facebookLink,
                    instagramLink,
                  },
                  index
                ) => (
                  <MemberItem
                    role={role}
                    name={name}
                    degree={degree}
                    image={formatImageData(image)}
                    description={formatMarkdownData(description)}
                    webLink={webLink}
                    twitterLink={twitterLink}
                    linkedinLink={linkedinLink}
                    facebookLink={facebookLink}
                    instagramLink={instagramLink}
                    width={getSize(type, [width, width / 2, width / 2])}
                    key={index}
                  />
                )
              )}
            </div>
          </Container>
        </SimpleContentLayer>
      </MenuWrapper>
    </PageContainer>
  )
}

export default injectIntl(TeamPage)
